import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import Rule from '../components/rule';

const OrgPage = (props) => {
    const { data: { file: { childImageSharp: { fixed }}}} = props;
    return (
      <Layout>
        <Helmet>
            <title>SA Mälestusselts „Artur Sirk“</title>
            <meta property="og:title" content="SA Mälestusselts „Artur Sirk“" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.artursirk.ee/selts/" />
            <meta property="og:description" content="Sihtasutuse loomise mõte pandi idanema juba pea aasta tagasi, kui ajaloost huvituv ning antud temaatikat uuriv Jaak Madison võttis kontakti mitmeid raamatuid Vabadussõjalastest ja Artur Sirgust kirjutanud pr Evy Laamann-Kalbusega. Evy Laamann-Kalbus on Vabadussõja veterani Paul Laamanni tütar, kes oli perekonnasõber Artur Sirgu perekonnaga. Proua elab väliseestlasena Washingtoni lähistel ning süvitsi tegelenud Artur Sirgu elulooga ning andis sellega indu ka noorematele põlvkondadele, seega ka minule, jätkata selle riigimehe ja suure tähega Eestlase mälestuse hoidmist ning väärtustamist. Tänasel päeval on häbiväärselt vähe pühendatud koolide ajalootundides Vabadussõjalastele, kes üritasid päästa Eestit 1930ndatel poliitilisest korruptsioonist ning kartellistumisest, millega peame võitlema ka tänasel päeval. Kahjuks lõppes nende siiras soov ja tahe arendada rahvusriiklust riiklike repressioonide ning vangistamistega. Mitte ainult - selle liikumise peamisele juhile ning rahva ühendajale tõenäoliselt mõrvaga - 31. juulil 1937. aastal kukkus Artur Sirk Luksemburgis Echternachi linna väikeses hotellis aknast alla ning suri haiglas 2. augustil. Kõik juhtumi asjaolud, nagu Sirgu kirjutatud raamatu käsikirja kadumine, lahkamise mitte-teostamine, kolme Poliitilise Politsei agendi sama-aegne lähetuses viibimine, viitavad selgelt poliitilisele tellimustööle." />
        </Helmet>
        <Rule />
        <h2 style={{textAlign: "center"}}>Mälestusselts</h2>
        <h3>„Artur Sirk“</h3>
        <p>Sihtasutuse loomise mõte pandi idanema juba pea aasta tagasi, kui ajaloost huvituv ning antud temaatikat uuriv Jaak Madison võttis kontakti mitmeid raamatuid Vabadussõjalastest ja Artur Sirgust kirjutanud pr Evy Laamann-Kalbusega. Evy Laamann-Kalbus on Vabadussõja veterani Paul Laamanni tütar, kes oli perekonnasõber Artur Sirgu perekonnaga. Proua elab väliseestlasena Washingtoni lähistel ning süvitsi tegelenud Artur Sirgu elulooga ning andis sellega indu ka noorematele põlvkondadele, seega ka minule, jätkata selle riigimehe ja suure tähega Eestlase mälestuse hoidmist ning väärtustamist. Tänasel päeval on häbiväärselt vähe pühendatud koolide ajalootundides Vabadussõjalastele, kes üritasid päästa Eestit 1930ndatel poliitilisest korruptsioonist ning kartellistumisest, millega peame võitlema ka tänasel päeval. Kahjuks lõppes nende siiras soov ja tahe arendada rahvusriiklust riiklike repressioonide ning vangistamistega. Mitte ainult - selle liikumise peamisele juhile ning rahva ühendajale tõenäoliselt mõrvaga - 31. juulil 1937. aastal kukkus Artur Sirk Luksemburgis Echternachi linna väikeses hotellis aknast alla ning suri haiglas 2. augustil. Kõik juhtumi asjaolud, nagu Sirgu kirjutatud raamatu käsikirja kadumine, lahkamise mitte-teostamine, kolme Poliitilise Politsei agendi sama-aegne lähetuses viibimine, viitavad selgelt poliitilisele tellimustööle.</p>
        <p>Need on asjaolud, mida ei saa ega tohi unustada, sest on osaks meie ajaloost ja riiklikust arengust. Me peame väärtustama nende meeste, eelkõige nende juhi, panust oma riigi arendamisse. Sihtasutus “Artur Sirk” sai oma praegu kuju 2016. aasta juulikuu lõpus, kui Jaak Madison asutas koos Triin Kuusega selle mälestuseltsi, mille nõukogusse kuuluvad ajaloolane Jaak Valge, korporatsioon Sakala vilistlane Art Johanson ning ajaloo uurija Daneil Mereäär. Mälestusseltsi asutajad on üritanud koondada sihtasutuse tööse erinevate valdkondade inimesi ning sellest tulenevalt on ka nõukogu eripalgeline - ajaloolane hr Valge, korp! Sakala vil! Johanson, kuna Artur Sirk oli sakalanus ning kodanikuaktivist hr Mereääre, kes on uurinud ja huvitunud Eesti ajaloost ning Vabadussõjalaste suur austaja. Ühise töö tulemusel on meie eesmärk teadvustada ühiskonnas Artur Sirgu osatähtsut Eesti ajaloos läbi avalike ürituste, seminaride, trükiste ning esimese suurema tööna ausamab püstitamise! Aastakümneid on arutletud ausamba püstitamisest Sirgule, kuid siiani on see tegemata. Mälestusselts „Artur Sirk“ on võtnud selle oma esmaseks ülesandeks ning selle töö tulemusel on loodetav ausamba avamine 2. augustil 2017. aastal ehk Artur Sirgu 80. surma-aastapäeval.</p>
        <Rule />
        <div className="o-grid o-grid--wrap" style={{marginBottom: "18px"}}>
            <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-50@large">
                <h2>Juhatus</h2>
                <div
                    className="o-grid o-grid--center o-grid--wrap"
                    itemScope={true}
                    itemProp="Person"
                    itemType="http://schema.org/Person"
                >
                    <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-50@small o-grid__cell--width-33@large">
                        <Img itemProp="image" fixed={fixed} alt="" />
                    </div>
                    <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-50@small o-grid__cell--width-66@large member-details">
                        <p className="last">
                            <span itemProp="name">Triin Kuusk</span>
                            <br />
                            <span itemProp="telephone">+372 5332 3059</span>
                            <br />
                            <a itemProp="email" href="mailto:sartinat@gmail.com">sartinat@gmail.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-50@large">
                <h2>Nõukogu</h2>
                <div
                    className="o-grid o-grid--center member-details"
                    itemScope={true}
                    itemProp="Person"
                    itemType="http://schema.org/Person"
                >
                    <p className="o-grid__cell" itemProp="name">Jaak Valge</p>
                    <p className="o-grid__cell" itemProp="name">Art Johanson</p>
                    <p className="o-grid__cell" itemProp="name">Daniel Mereäär</p>
                </div>
            </div>
        </div>
        <Rule last />
      </Layout>
    );
};

export default OrgPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "org/triinkuusk.png" }) {
            childImageSharp {
                fixed(width: 150, height: 150) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;